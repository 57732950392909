@font-face {
    font-family: 'Ogg Text';
    src: url('../../public/fonts/OggText-Light.woff2') format('woff2'),
        url('../../public/fonts/OggText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Flexa Condensed';
    src: url('../../public/fonts/GTFlexa-CondensedRegular.woff2') format('woff2'),
        url('../../public/fonts/GTFlexa-CondensedRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sporting Grotesque';
    src: url('../../public/fonts/SportingGrotesque-Normal_0_wt.woff2') format('woff2'),
        url('../../public/fonts/SportingGrotesque-Normal_0_wt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roslindale';
    src: url('../../public/fonts/Roslindale-DisplayCondensedLight.woff2') format('woff2'),
        url('../../public/fonts/Roslindale-DisplayCondensedLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

:root {
    font-size: $font-size-root;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

body {
    @include font-body();
    font-family: $font-ogg;
    color: $color-text;
    overflow-x: hidden;
    background-color: #9B969F;
}

section {
    margin: $margin-section 0;
}

a {
    color: $color-text;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}

a.link::before,
a.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: $color-text;
    bottom: -30%;
    left: 0;
    pointer-events: none;
}

a.link::before {
    content: '';
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

a.link:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}

h1,
h2 {
    @include font-heading();
}

h3,
h4,
h5,
h6 {
    @include font-subheading();
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
small {
    @include reset-space();
}

small {
    @include font-small();
}

img {
    max-width: 100%;
}

::selection {
    color: $color-background;
    background: $color-text;
}

// html[data-direction="down"] #header {
//     transform: translate3d(0, -100%, 0);
// }