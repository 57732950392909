.title-center {
    display: block;
    margin: auto;
    text-align: center;
}

.link {

    &-icon {
        vertical-align: middle;
    }
}


.videoplay {
    position: relative;
    overflow: hidden;

    &-text {
        width: 3rem;
        height: 3rem;
        text-align: center;
        border-radius: 100%;
        padding: 2.5rem;
        border: 1px solid $color-background;
        color: $color-background;
        position: absolute;
        z-index: 3;
        cursor: pointer;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: padding 0.3s ease;

        &:hover {
            padding: 2.8rem;

            ~.videoplay-overlay {
                opacity: 0.3;
            }
        }
    }

    &-overlay {
        position: absolute;
        z-index: 2;
        left: 0;
        top: -8px;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.5;
        transition: opacity 0.3s ease;
    }

    &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
    }
}

.border {
    width: 100%;
    border-bottom: 1px solid $color-text;
}


.masking-text {
    overflow: hidden;
    display: block;

    .line {
        position: relative;
    }
}

.reveal-image {
    position: relative;
    overflow: hidden;
    visibility: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform-origin: left;
    }
}

.parallax {
    margin-top: $margin-section * 1.5;
    margin-bottom: $margin-section * 1.5;
    width: 100%;
    height: 80vh;
    overflow: hidden;

    &-image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    &-overlay {
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0.7;
    }
}

.width-full {
    width: 100%;
}

.height-full {
    height: 100%;
}

.img-full {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.btn {
    @include font-size(1.15rem);
    font-family: $font-sporting;
    line-height: 0;
    border-radius: 150px;
    padding: 1rem 2rem;
    background: #F8F2E9;
    color: $color-black;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 3px 2px rgba($color: #000000, $alpha: 0.3);
    transition: all 0.3s ease;

    .icon {
        margin-left: 2rem;
        position: relative;
        right: -0.8rem;

        &::before {
            content: '';
            position: absolute;
            left: -0.8rem;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 50%;
            background: $color-black;
        }
    }

    &:hover {
        background: $color-primary3;
    }
}

.transform-none {
    text-transform: none !important;
}

.first-section {
    margin-top: 0;
    padding-top: $margin-section - 2.5;
}

.max-text {
    max-width: 600px;
}

.swiper-button-prev,
.swiper-button-next {
    color: $color-primary !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
}

.swiper-pagination {
    position: relative !important;
    background: $color-primary !important;
    display: flex !important;
    padding: 0.8rem 1rem !important;
    justify-content: center !important;
}

.swiper-pagination-bullet {
    background: $color-background !important;
    opacity: 0.5 !important;
}

.swiper-pagination-bullet-active {
    background: $color-background !important;
    opacity: 1 !important;
}

.swiper-slide-desc {
    position: absolute;
    bottom: 10px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    color: #FFFFFF;
    text-align: center;
    @include font-size($font-size-small);
    padding: 5px 0;
}

.learn-text-all {
    padding-top: 1rem;
    padding-left: 0;

    @include media-desktop {
        padding-top: 0;
        padding-left: 2rem;
    }
}

.half-stripe {
    background-image: linear-gradient(0, $color-primary 40%, transparent 40%);
    display: inline;
}

.titlestr {
    margin-bottom: 7rem;
}

.text-gray {
    color: $color-gray;
}