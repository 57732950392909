#header {
  position: fixed;
  width: 100%;
  @include font-size(1.3rem);
  padding: 3rem 0;
  color: $color-white;
  background-image: linear-gradient(180deg, $color-background, transparent);
  z-index: 999;

  a {
    color: $color-white;
  }

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-title {

      &-text {
        @include font-size(1.6rem);
        font-family: $font-sporting;
        display: inline-block;
        position: relative;

        a {
          transition: all 0.3s ease;

          &:hover {
            color: $color-primary2;

            &::after {
              background: $color-primary2;
            }
          }

          &::after {
            transition: all 0.3s ease;
            content: '';
            position: absolute;
            right: -10%;
            top: 30%;
            background: $color-white;
            height: 7px;
            width: 7px;
            border-radius: 100%;
          }
        }
      }
    }

    &-menu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 0;

      @include media-desktop() {
        padding: 0;
        width: auto;
        padding: 0 15px;
      }

      &-avail {
        position: relative;

        &-open {
          display: none;
          opacity: 0;
          transform: translateY(10px);
          position: absolute;
          top: 780%;
          left: -10%;
          width: 250%;
          height: auto;
          background: rgba($color: #000000, $alpha: 0.9);
          padding: 3rem;
          border-radius: 15px;
          line-height: 1.8;
          text-align: left;
          color: $color-white;
          cursor: default;
          pointer-events: none;
          font-family: $font-sporting;
          @include font-size(1rem);

          @include media-desktop() {
            left: -75%;
          }

          &::before {
            content: '';
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;

            border-bottom: 15px solid black;
          }

          &-shapes {
            margin: 2rem auto 4rem auto;
            width: 100%;
            display: block;

            .active {
              fill: white;
            }
          }
        }
      }

      &-border {
        width: 1px;
        height: auto;
        height: 20px;
        opacity: 0.3;
        background: $color-white;

        @include media-desktop() {
          margin-right: 3rem;
        }
      }

      &-btn {
        line-height: 0;
        // padding: 0.9rem 2rem 1rem 2rem;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;

        @include media-desktop() {
          margin-right: 3rem;
        }

        &:last-child {
          margin-right: 0;

          // &::before {
          //   content: '';
          //   left: -25%;
          //   position: absolute;
          //   width: 1px;
          //   height: 50%;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   opacity: 0.3;
          //   background: $color-white;
          // }
        }

        .icon {
          margin-left: 2rem;
          position: relative;
          right: -0.8rem;
        }

        &:hover {
          color: $color-primary3;
        }
      }
    }
  }
}

.menu-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 99999;
  color: #29120E;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0;
  display: none;
  background-size: 100%;
  background: linear-gradient($color-primary, rgba(255, 255, 255, 0));
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $color-primary), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -moz-linear-gradient($color-primary, rgba(255, 255, 255, 0));
  background: -webkit-linear-gradient($color-primary, rgba(255, 255, 255, 0));


  @include media-desktop() {
    width: 7%;
    height: 100%;
    transform: translateX(-100%);
    background: #D8CCBD;
    border-radius: 0 15px 15px 0;

    &:hover {
      background: #E2D5C6;
      width: 5%;
    }
  }

  .menu-nav {

    &-wrap {
      width: 100%;
      z-index: 2;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;
      padding: 2rem 0;
      flex-direction: row;

      @include media-desktop() {
        height: 100vh;
        padding: 3rem 0;
        flex-direction: column;
      }
    }

    &-logo {
      font-family: $font-sporting;

      @include media-desktop() {
        transform: translate(45%, 50%) rotate(-90deg);
        transform-origin: left;
      }

      &-text {
        @include font-size(1.2rem);
        display: inline-block;
        position: relative;
        color: #9C8B81;
        margin-right: 15px;

        @include media-desktop() {
          color: #29120E;
          margin-right: 0;
        }

        &::after {
          transition: all 0.3s ease;
          content: '';
          position: absolute;
          right: -10%;
          top: 30%;
          background: #9C8B81;
          height: 5px;
          width: 5px;
          border-radius: 100%;
        }

        @include media-desktop() {
          &::after {
            height: 7px;
            width: 7px;
            background: $color-black;
          }
        }
      }
    }

  }
}

#menu {
  transform: translateX(100%);
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  color: $color-black;
  background: $color-primary;
  padding: 9rem 0 2rem 0;
  overflow-y: scroll;
  overflow-x: hidden;

  @include media-desktop() {
    padding: 9vh 0;
    overflow: hidden;
  }

  ul {
    list-style-type: none;
  }

  li {
    .line {
      transform: translateY(101%);
      transform-origin: left;
    }
  }

  h3 {
    margin-bottom: 1.5rem;
    letter-spacing: 3px;
  }

  h4 {
    @include font-size(1.8rem);
    font-family: $font-ogg;
    margin-bottom: 1.5rem;
    line-height: 1.3;
    text-transform: none;
  }

  .btn {
    background: $color-black;
    color: $color-primary;
    box-shadow: none;
    padding: 1.5rem 1.8rem 1.5rem 2rem;
    text-transform: uppercase;
    font-family: $font-gt;
    @include font-size(1rem);
    letter-spacing: 3px;
    transition: background 0.3s ease;

    .plus {
      margin-left: 0.8rem;
      color: $color-text;
    }

    &:hover {
      background: #E0D0BD;
      color: $color-black;

      .close path {
        fill: $color-black
      }

      .plus {
        color: $color-black;
      }
    }

    .close {
      position: absolute;
      display: none;
      opacity: 0;
    }
  }

  .menu {

    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      @include media-desktop() {
        flex-direction: row;
        transform: translateX(15%);
      }
    }

    &-client {
      order: 3;
      margin-top: 5rem;

      @include media-desktop() {
        order: 1;
        transform: none;
        margin-top: 0;
      }

      li {
        @include font-size(8vw);
        margin-bottom: 0.8rem;

        @include media-desktop() {
          @include font-size(2.4vw);
        }

        .small {
          vertical-align: super;
          @include font-size(4vw);

          @include media-desktop() {
            @include font-size(1.5vw);
          }
        }
      }
    }

    &-services {
      order: 1;

      @include media-desktop() {
        order: 2;
      }

      li {
        @include font-size(5vw);
        margin-bottom: 0.4rem;

        @include media-desktop() {
          @include font-size(1.35vw);
        }
      }

      &-list {
        padding-bottom: 2rem;
      }
    }

    &-stack {
      // margin-top: 5rem;
      transform: translateY(40px);
      opacity: 0;
      display: none;
      order: 2;

      @include media-desktop() {
        order: 3;
        transform: translateX(40px) scale(0.9);
        margin-top: 0;
        display: block;
      }

      li {
        @include font-size(6vw);
        margin-bottom: 0.4rem;

        @include media-desktop() {
          @include font-size(1.5vw);
        }
      }
    }
  }
}

#cursor {
  display: none;

  @include media-desktop {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;

    .cursor__circle {
      display: block;
      width: 300px;
      height: 400px;
      margin-top: -65%;
      margin-left: -50%;
      position: relative;
      overflow: hidden;

      .cursor__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        z-index: 1;
        opacity: 0;
        transform-origin: top;
      }
    }
  }
}

#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: $color-background;
  color: $color-text;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: right;
}

#app {
  position: relative;
  overflow: hidden;
}

#main {
  overflow: hidden;
}

#footer {
  @include font-size(1.1rem);
  font-family: $font-sporting;
  padding: 4rem 0 8rem 0;
  color: $color-primary;

  a {
    color: $color-primary;
  }
}

.grain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  cursor: none;
  background-image: url('../../public/grain.png');
  background-repeat: repeat;
  opacity: 0.7;
}