@import '~rfs/scss';

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    $font-size-root: 14px;

    :root {
        font-size: $font-size-root;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

@media (min-width: 991.98px) {
    .container {
        max-width: 90%;
    }

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

@media (min-width: 1199.98px) {}

// Desktop size
@media (min-width: 1399.98px) {
}
