#intro {
  margin-top: 0;
  margin-bottom: $margin-section;
  padding-top: $margin-section;
  position: relative;

  @include media-desktop() {
    margin-bottom: $margin-section * 3;
  }

  .intro {

    &-logo {
      @include font-size(1.6rem);
      color: $color-white;
      font-family: $font-sporting;
      display: inline-block;
      position: relative;
      margin-bottom: 3rem;
      opacity: 0;

      &::after {
        transition: all 0.3s ease;
        content: '';
        position: absolute;
        right: -10%;
        top: 30%;
        background: $color-white;
        height: 7px;
        width: 7px;
        border-radius: 100%;
      }
    }

    &-title {
      @include font-size($font-size-big * 2.5);
      font-family: 'Roslindale';
      color: $color-primary2;
      margin-bottom: 13vh;

      @media (min-width: 767.98px) {
        @include font-size($font-size-big * 1.6);
      }

      @media (min-width: 991.98px) {
        @include font-size($font-size-big);
        margin-top: -2rem;
        margin-bottom: 4rem;
      }

      .line {
        transform: translateY(101%);
      }

    }

    &-btn {
      padding: 1.2rem 3.2rem;
      opacity: 0;
      @include font-size(1.8rem);

      @include media-desktop() {
        @include font-size(1.15rem);
        padding: 1rem 2rem;
      }
    }

    &-radial {
      position: relative;
      bottom: 20vh;
      left: -5%;
      z-index: -1;
      pointer-events: none;

      @include media-desktop() {
        bottom: 15vw;
      }

      &-anim,
      &-bg {
        position: absolute;
      }

      &-bg {
        max-width: 250%;
        top: -20vh;

        @include media-desktop() {
          max-width: 100%;
          top: none;
        }
      }

      &-anim {
        left: 0;
        max-width: 50vh;
        opacity: 0;
        transform: scale(1) rotate(90deg);

        @include media-desktop() {
          transform: scale(0.8) rotate(90deg);
          left: 3%;
          max-width: 40vw;
        }
      }
    }
  }
}

#about {
  margin-top: $margin-section * 2.8;

  @include media-desktop() {
    margin-top: $margin-section * 4;
  }

  p {
    line-height: 1.9;
    max-width: 300px;

    @media (min-width: 575.98px) {
      line-height: 2.2;
      margin: 0;
      max-width: none;
    }
  }
}